<template>
  <div class="container" style="background: #183457">
    <div class="row vh-100">
      <div class="col-lg-6" style="margin: auto;">
        <div class="row">
          <div class="col logo-box" >
            <img
              src="../assets/TNT_LOGO_GOLD_02.png"
              width="70%"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="row social-details" style="color: white; margin-left: 15%">
          <p>
            <i class="fab fa-instagram fa-lg"></i> <span>@thenetworktap</span>
          </p>
          <br />
          <br />
          <p>
            <i class="fas fa-globe fa-lg"></i> <span>thenetworktap.com</span>
          </p>
          <br />
          <br />
          <p>
            <i class="far fa-envelope fa-lg"> </i>
            <span>thenetworktap@gmail.com</span>
          </p>
        </div>
      </div>
      <div class="col-lg-6 login-form-box">
        <!-- <div class="row" style="height: 10%; text-align:center; margin-top:40%;" >
        <h2 >Login</h2> 
      </div> -->
        <div class="row login-form-main-box">
          <h2 class="text-center mb-5">Login</h2>
          <form class="login-form" @submit.prevent="login">
            <div class="form-group">
              <!-- <label for="loginemail">Email Address</label> -->
              <input
                class="form-control form-rounded"
                type="email"
                name="loginemail"
                id="loginemail"
                placeholder="Email"
                style="width: 50%"
                v-model.trim="loginEmail"
                required
              />
            </div>
            <br />
            <div class="form-group">
              <!-- <label for="loginPassword">Password</label> -->
              <input
                class="form-control form-rounded"
                type="password"
                name="loginPassword"
                id="loginPassword"
                placeholder="password"
                style="width: 50%"
                v-model.trim="loginPassword"
                required
              />
            </div>
            <br />
            <div style="text-align: center">
              <p v-if="this.loginError != ''" style="color: red">
                {{ this.loginError }}
              </p>
            </div>
            <div style="text-align: center">
              <button
                type="submit"
                value="login"
                class="btn btn-primary"
                style="width: 50%"
              >
                Login
              </button>
            </div>
          </form>
          <div style="text-align: center">
            <a href="/signUp" style="color: blue; font-size: 80%">Sign Up</a>
            <br/>
            <a href="/resetPassword" style="color: blue; font-size: 80%">Forget Password?</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import $ from "jquery";
import {
  getAuth,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";
// import {
//   getAuth,
//   signInWithEmailAndPassword,
//   browserSessionPersistence,
//   setPersistence,
// } from "firebase/auth";
// import axios from "axios";
import router from "@/router";
import store from "../store";

export default {
  data() {
    return {
      // login info
      loginEmail: "",
      loginPassword: "",
      loginError: "",
    };
  },
  methods: {
    login() {
      const auth = getAuth();

      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          return signInWithEmailAndPassword(
            auth,
            this.loginEmail,
            this.loginPassword
          )
            .then((userCredential) => {
              const userid = userCredential.user.uid;
              // console.log(userid);
              store.commit("addUserId", userid);
              router.push("/editUser");
              // this.setToken(this.token)
            })
            .catch(() => {
              // Handle error
              this.loginError = "Username/Password Error";
            });
        })
        .catch(() => {
          this.loginError = "Error Logging In";
        });
    },
  },

  // mounted() {
  //   $(document).ready(function () {
  //     "use strict";

  //     // Detect browser for css purpose
  //     if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
  //       $(".form form label").addClass("fontSwitch");
  //     }

  //     // Label effect
  //     $("input").focus(function () {
  //       $(this).siblings("label").addClass("active");
  //     });
  //   });
  // },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
body {
  font-family: "Montserrat", sans-serif;
}
p {
  font-family: "Montserrat", sans-serif;
  font-size: 120%;
}
span {
  padding-left: 1%;
}
.container {
  max-width: 100%;
}
input {
  margin: auto !important;
}

.login-form-main-box {
  margin-top: 40%;
}

.login-form-box {
  background-color: #f5f5f5;
  border-bottom-left-radius: 80px 80px;
  border-top-left-radius: 80px 80px;
}
.logo-box{
  margin-left:10%
}

@media screen and (max-width: 968px) {
  .login-form-main-box {
    margin-top: 10%;
  }

  .login-form-box {
    border-bottom-left-radius: 0;
    border-top-left-radius: 80px 80px;
    border-top-right-radius: 80px 80px;
  }
  .logo-box{
    margin-left:20%
  }
  .social-details{
    font-size: 75%;
  }
}
</style>