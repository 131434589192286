
import { createStore } from 'vuex'
import createdPersistedState from "vuex-persistedstate"

export default createStore({
  state: {
    userId:""
  },
  getters:{
    getUserId(state){
      return state.userId
    }
  },
  mutations: {
    addUserId(state, data){
      state.userId = data
    }
  },
  actions: {
  },
  modules: {
  },
  plugins:[createdPersistedState()]
})
